.icon_footer_container > a > i{
  font-size: 2.15em;
  line-height: .05em;
  vertical-align: -.28em
}

.footer_logo{
  width: 80%;
  height: 80%;
  margin-bottom: 50px;
}

.footer_logo_container{
  display: flex;
  justify-content: center;
  text-align: center;
}

.footer_logo_container > div{
  text-align: center;
  margin: auto;
}