@font-face {
  font-family: lavendar;
  src: url("./beauty-mountains-font/BeautyMountainsPersonalUse-od7z.ttf");
}

@font-face {
  font-family: mandala;
  src: url("./mercusuar-medium-script-font/MercusuarMediumScript-d94yl.ttf");
}

.container{
  max-width: 100%;
}

.menu_title{
  font-family: lavendar;
  font-weight: normal;
  font-size: calc(max(40px, 2.2vw));
}

.menu_item_category_title{
  font-family: sans-serif;
  font-weight: normal;
  font-size: calc(max(35px, 1.9vw));
}

.menu_item_wrapper{
  position: relative;
  width: calc(max(20vw, 300px));
}

.menu_title_container{
  width:100%;
  margin: auto;
  justify-content: center;
  display:flex;
}

.menu_item_picture{
  width: 100%;
  height: 100%;
}

.menu_item_picture_wrapper{
  display: none;
  position: absolute;
}

.menu_item_container:hover ~ .menu_item_picture_wrapper{
  display: block;
  position: absolute;
  z-index: 2;
  top:0;
  left: 200px;
}

.menu_item_header > p{
  margin-bottom: 10px;
  margin-top: 0;
  font-size: calc(max(25px, 1.3vw));
}

.menu_item_ingredient{
  margin-top: 0;
  margin-bottom: 20px;
  font-size: calc(max(23px, 1.2vw));
  width: 80%;
}

.menu_item_header{
  display: flex;
}

.menu_item_header > p{
  margin: auto;
  width:  50%;
}

.menu_item_price{
  text-align: right;
}

.wd-100{
  width: 100%;
}

.menu_item_picture_wrapper.show{
  display: block;
  position: relative;
}