.parallax{
    width: 100%;
    aspect-ratio: 1/0.8;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.caption{
    position: absolute;
    left: 0%;
    top: 50%;
    border: none;
    text-align: center;
    width: 100%;
}

.caption > span{
    padding: 25px 20px;
    background-color: #FFF5B2;
    font-family: 'Playfair Display', serif;
}

#Eggrolls_text > span{
    background-color: #F7B32B;
    color: #ECDCB0;
    font-weight: 100;
}

#Springrolls_text > span{
    background-color: #F9F8F8;
    color: #FF7F51;
    font-weight: 100;
}