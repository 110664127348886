.navbar_wrapper{
    position: relative;
    width: 100%;
    min-width: 400px;
    min-height: 200px;
}

.navbar_container{
    width: 90%;
    min-height: 200px;
    position: relative;
    display: flex;
    margin: auto;
}

.logo_wrapper{
    position: relative;
    min-width: 250px;
    min-height: 200px;
}

.logo_wrapper.resize{
    position: relative;
    width: 50%;
    aspect-ratio: 1/0.75;
}

.logo_wrapper > a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}

.logo_wrapper > a > img{
    width: 100%;
    height: 100%;
}

.nav_icon_wrapper{
    width: 23%;
    margin-top: auto;
    margin-bottom: auto;
}

.nav_icon_container{
    width: 40%;
    text-align: center;
}

.nav_icon_wrapper > div > svg{
    width: 100%;
    height: auto;
    padding: 5px 6px;
}

.nav_icon_wrapper > div > svg:active{
    border-radius: 50%;
    background-color: lightgrey;
}

.nav_icon_container > svg:active{
    border-radius: 50%;
    background-color: lightgrey;
}

.nav_wrapper{
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
}

.nav_wrapper > .button_wrapper{
    min-width: 200px;
    min-height: 80px;
}

.nav_wrapper > .button_wrapper > .button_container{
    position: relative;
    width: 100%;
    height: 100%;
}

.nav_wrapper > .button_wrapper > .button_container > a{
    text-decoration: none;
    color: black;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    text-align: center;
    justify-content: center;
    justify-items: center;
    font-size: x-large;
    font-family: 'Playfair Display', serif;
}

.nav_wrapper > .button_wrapper > .button_container > a > div{
    width: 100%;
    margin-top: 24px;
}

.nav_wrapper > .button_wrapper:hover{
    /* background-color: #ADF1D2; */
    /* background-color: hsl(154, 79%, 71%); */
    background-color: hsl(48, 59%, 68%);
    box-shadow: 5px 10px 10px 2px hsl(48, 59%, 68%, 0.3);
}

.nav_dropdown_wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    transition-duration: 400ms;
    visibility: hidden;
}

.nav_dropdown_wrapper.active{
    visibility: visible;
    transform: translateX(0);
    transition-duration: 400ms;
}

.nav_dropdown_container{
    width: 100%;
    position: relative;
    background-color: white;
}

.nav_dropdown_menu_icon{
    width: 90%;
    display: flex;
    min-height: 200px;
    margin: auto;
}

.nav_menu_icon_wrapper{
    width: 23%;
    margin-top: auto;
    margin-bottom: auto;
}

.nav_menu_icon_wrapper > div > svg{
    width: 100%;
    height: auto;
    padding: 5px 6px;
} 

.nav_dropdown_menu_icon > .placeholder{
    width: 50%;
    aspect-ratio: 1/0.75;
}

.nav_dropdown_menu{
    width: 95%;
}

.nav_dropdown_menu > .button_wrapper > .button_container > a{
    color: black;
    text-decoration: none;
    text-align: center;
    font-family: 'Playfair Display', serif;
}

.nav_dropdown_menu > .button_wrapper{
    margin-top: 35px;
    margin-bottom: 35px;
    text-align: center;
}

.nav_dropdown_menu > .button_wrapper:last-child{
    padding-bottom: 35px;
}

.nav_dropdown_menu > .button_wrapper > .button_container{
    display: inline-block;
}