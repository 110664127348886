.fav_screen_wrapper{
    width: 100%;
    /* background-color: #F8F4E3; */
    background-color: #FFE0B5;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.fav_screen_container{
    width: 90%;
    margin: auto;
    position: relative;
}

.screen_placeholder{
    position: relative;
    width: 80%;
    aspect-ratio: 1/0.45;
}

.screen_placeholder.next{
    /* visibility: hidden; */
    transition-duration: 1.5s;
}

.next_screen_placeholder{
    position: absolute;
    width: 80%;
    aspect-ratio: 1/0.45;
    top: 0;
    left: 90%; 
}

.next_screen_placeholder.next{
    transition-duration: 1.5s;
}

.first_half, .second_half{
    width: 14%;
    aspect-ratio: 1/2.33;
    z-index: 1;
    position: absolute;
    background-color: #FFE0B5;
}

.first_half{
    top: 0;
    left: 0;
}

.second_half{
    right: 0;
    top : 0;
}