#main{
    width: 100%;
    height: 100%;
    min-width: 400px;
    position: relative;
}

body, html {
    border: 0;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
}