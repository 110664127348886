.description_section{
    padding: 50px 80px;
    text-align: justify;
}

.description_section:last-of-type{
    padding-bottom: 100px;
}

.description_title{
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 1.75em;
}

.description_para{
    font-size: 1.5em;
    font-family: 'Playfair Display', serif;
}